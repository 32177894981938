/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import {
  NiagaraButton2,
  NiagaraIcon,
  NiagaraModal,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Modal } from '@scuf/common';
import { useUserRoleStore } from '../../../../userroles/UseUserRoleStore';
import { getNotificationSettingsAsync, saveNotificationSettingsAsync } from '../../../../api/notifications';
import { ApiError } from '../../../../utils/niagaraHttp';
import './UnsubscribeEmailNotificationsModal.scss';
import { ApiStatus } from '../../../../utils/Types';
import Status from '../../../../utils/Status';
import { useSearchParams } from 'react-router-dom';
import { UnsubscribeErrorPage } from './UnsubscribeErrorPage';
export function UnsubscribeEmailNotificationsModal({ onClose }: { onClose: () => void }) {
  const [params, setParams] = useSearchParams();
  const [showSubscribedSuccessfully, setShowSubscribedSuccessfully] = useState<boolean>(false);
  const [status, setStatus] = useState<ApiStatus>('idle');
  const [error, setError] = useState<ApiError | undefined>();
  const notificationType = params.get('notificationType');
  const unsubscribeNotificationType = notificationType === '1' ? 'Service account updates' : 'Subscription updates';

  const {
    currentUser: { id: userId },
  } = useUserRoleStore();

  useEffect(() => {
    if (userId) {
      getNotificationSettings();
    }
  }, [userId]);

  const getNotificationSettings = () => {
    setStatus('pending');
    getNotificationSettingsAsync(userId)
      .then((response) => {
        const getNotifications = response.filter(
          (n) => n.eventTypeId === Number(notificationType) && n.subscribe && n.notificationType === 'email'
        );
        setShowSubscribedSuccessfully(getNotifications.length === 0);
      })
      .catch((_: ApiError) => setShowSubscribedSuccessfully(false))
      .finally(() => setStatus('fulfilled'));
  };
  const saveNotificationSettings = () => {
    if (!userId || !notificationType) return;
    setStatus('pending');
    setError(undefined);
    const payload = [
      {
        eventTypeId: Number(notificationType),
        notificationType: 'email',
        subscribe: false,
      },
    ];

    saveNotificationSettingsAsync(userId, payload)
      .then(() => {
        setStatus('fulfilled');
        setShowSubscribedSuccessfully(true);
        params.delete('notificationType');
        setParams(params);
      })
      .catch((e: ApiError) => {
        setStatus('rejected');
        setError(e);
      });
  };

  return (
    <NiagaraModal className='unsubscribe-notifications-modal' open={true} size='large'>
      <Modal.Header>
        <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <Status
          status={status === 'rejected' ? 'fulfilled' : status}
          children={
            status === 'rejected' || !!error ? (
              <UnsubscribeErrorPage onRetry={() => saveNotificationSettings()} />
            ) : showSubscribedSuccessfully ? (
              <UnSubscribedSuccessfully unsubscribeNotificationType={unsubscribeNotificationType} />
            ) : (
              <ProceedToUnSubscribe
                unsubscribeNotificationType={unsubscribeNotificationType}
                onClose={onClose}
                saveNotificationSettings={saveNotificationSettings}
              />
            )
          }
        />
      </Modal.Content>
    </NiagaraModal>
  );
}

export function ProceedToUnSubscribe({
  unsubscribeNotificationType,
  onClose,
  saveNotificationSettings,
}: {
  unsubscribeNotificationType: string;
  onClose: () => void;
  saveNotificationSettings: () => void;
}) {
  return (
    <>
      <div className='heading'>Are you sure you want to unsubscribe?</div>
      <div className='content'>
        <span>
          You will no longer be using the email notification service to provide updates regarding{' '}
          {unsubscribeNotificationType.toLowerCase()}.
        </span>
        <span>To confirm unsubscribe click on proceed.</span>
      </div>
      <div className='buttons'>
        <NiagaraButton2 type='secondary' content='Cancel' onClick={onClose} />
        <NiagaraButton2 type='primary' content='Proceed' onClick={() => saveNotificationSettings()} />
      </div>
    </>
  );
}

export function UnSubscribedSuccessfully({ unsubscribeNotificationType }: { unsubscribeNotificationType: string }) {
  return (
    <>
      <NiagaraIcon name='Success' color='#159D62' />
      <div className='heading'>Unsubscribed successfully</div>
      <div className='content'>
        <span>
          You will no longer receive emails regarding {unsubscribeNotificationType.toLowerCase()} from Niagara cloud.{' '}
        </span>
        <span>
          If you want to revert this action please login to the Niagara cloud portal and change the notification
          settings.
        </span>
      </div>
    </>
  );
}
