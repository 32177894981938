/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import { niagaraHttp } from '../../utils/niagaraHttp';
export interface NotificationSettings {
  eventTypeId: number;
  notificationType: string;
  subscribe: boolean;
}
export interface IContent {
  readStatus: boolean;
  subject: string;
  generatedDate: string;
  notificationId: number;
  content: string;
  substitutions: {
    customer_id?: string;
    service_account_id?: string;
    service_account_name?: string;
    service_account_client_id?: string;
    host_id?: string;
    license_id?: number;
    device_uuid?: string;
    subscription_name?: string;
    expiration_end_date: string;
    niagara_licensing_website?: string;
    number_of_days_for_expiry: string;
  };
}
export interface IAllNotifications {
  pagedMetaData: {
    number: number;
    size: number;
    totalPages: number;
    totalElements: number;
  };
  InAppPagedResponse: {
    content: IContent[];
  };
}

export interface IUpdateReadStatus {
  readStatus: boolean;
  readAllNotifications: boolean;
  notificationIds?: number[];
}

export async function getNotificationSettingsAsync(userId: number) {
  return niagaraHttp.get<NotificationSettings[]>(`${window.ENV.API_BASE_URL}/api/v1/notifications/users/${userId}`);
}

export async function saveNotificationSettingsAsync(userId: number, payload: NotificationSettings[]) {
  return niagaraHttp.put<NotificationSettings[]>(
    `${window.ENV.API_BASE_URL}/api/v1/notifications/users/${userId}`,
    payload
  );
}
//Testing userIds 1514,1420
export async function getAllNotificationAsync(
  userId: number,
  options: { readStatus: string; page: number; size: number }
) {
  const { readStatus, page, size } = options;
  const readStatusQueryParam = readStatus === 'unRead' ? `readStatus=false&` : '';
  return niagaraHttp.get<IAllNotifications>(
    `${window.ENV.API_BASE_URL}/api/v1/notifier/users/${userId}/inapp?${readStatusQueryParam}page=${page}&size=${size}&sortBy=generatedDate,desc`
  );
}

export async function updateReadStatusAsync(userId: number, payload: IUpdateReadStatus) {
  return niagaraHttp.put(`${window.ENV.API_BASE_URL}/api/v1/notifier/users/${userId}`, payload);
}
