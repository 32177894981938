/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useMemo } from 'react';
import {
  NiagaraBadge,
  NiagaraButton2,
  NiagaraIcon,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { getBadgeProperty } from './NotificationConstant';
import { NotificationProps } from './NotificationConstant';
export const NotificationItem = ({
  subject,
  generatedTime,
  content,
  expireInDays,
  readStatus,
  name,
  renewLinkHandler,
  handleMarkNotificationAsRead,
}: NotificationProps) => {
  const getBadge = (days: number) => {
    const badgeProperty = getBadgeProperty(days);
    return badgeProperty && <NiagaraBadge {...badgeProperty} />;
  };
  const readStatusValue = useMemo(() => {
    return readStatus;
  }, [readStatus]);
  return (
    <div className={`notifications-item${readStatusValue ? ' read' : ' unread'}`}>
      {!readStatusValue && (
        <div className='unread-icon'>
          <NiagaraIcon name='Status' color='#005EAC' size={8} />
        </div>
      )}
      <div className='notification-content'>
        <div className='notification-body'>
          <div className='title-content'>
            <div className='subject'>{subject}</div>
            <div className='description'>
              <span>{name}</span> {content}.
            </div>
          </div>
          <div className='expiray-badge'>
            <div className='mark-icon'>
              <NiagaraButton2
                type='secondary-link'
                icon='MarkAllRead'
                onClick={() => handleMarkNotificationAsRead()}
                iconSize={16}
                disabled={readStatus}
              />
            </div>
            <div className='notification-badge'>{getBadge(expireInDays)}</div>
          </div>
        </div>
        <div className='notification-footer'>
          <div className='expiry-date'>{generatedTime}</div>
          <div className='navigation-link'>
            <NiagaraButton2 type='primary-link' content='Renew' onClick={() => renewLinkHandler()} />
          </div>
        </div>
      </div>
    </div>
  );
};
