/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import { NiagaraIconName } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
export type INiagaraBadge = { className: string; icon: NiagaraIconName; label: string };
export const expiryIn7DaysProperty: INiagaraBadge = {
  className: 'red',
  icon: 'Alert',
  label: 'EXPIRY',
};
export const expiryInMoreThan7DaysProperty: INiagaraBadge = {
  className: 'orange',
  icon: 'Alert',
  label: 'EXPIRY',
};

export const getBadgeProperty = (days: number) => {
  if (days <= 7) return expiryIn7DaysProperty;
  if (days > 7 && days <= 30) return expiryInMoreThan7DaysProperty;
};

export type NotificationProps = {
  subject: string;
  generatedTime: string;
  content: string;
  expireInDays: number;
  readStatus: boolean;
  name: string;
  renewLinkHandler: () => void;
  handleMarkNotificationAsRead: () => void;
};
