/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */

import { useEffect, useState } from 'react';
import { ApiError } from '../../../utils/niagaraHttp';
import { ApiStatus } from '../../../utils/Types';
import { NotificationProps } from './NotificationConstant';
import {
  getAllNotificationAsync,
  IAllNotifications,
  IContent,
  IUpdateReadStatus,
  updateReadStatusAsync,
} from '../../../api/notifications';
import { useUserRoleStore } from '../../../userroles/UseUserRoleStore';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

export function useNotificationsController(onCloseNotification: (dataClose?: string) => void) {
  const {
    currentUser: { id: userId },
  } = useUserRoleStore();
  const navigate = useNavigate();
  const [status, setStatus] = useState<ApiStatus>('fulfilled');
  const [error, setError] = useState<ApiError>();
  const [allNotifications, setAllNotifications] = useState<NotificationProps[]>([]);
  const [unReadNotifications, setUnReadNotifications] = useState<NotificationProps[]>([]);
  const [allNotificationsCount, setallNotificationsCount] = useState<number>(0);
  const [unReadNotificationsCount, setUnReadNotificationsCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [notificationType, setNotificationType] = useState<'all' | 'unRead'>('all');
  const [lazyPaginationLoader, setLazyPaginationLoader] = useState<boolean>(false);
  const [pageSettings, setPageSettings] = useState<IAllNotifications['pagedMetaData']>({
    number: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
  });

  useEffect(() => {
    if (notificationType === 'all') {
      loadAllNotifications(currentPage);
      currentPage === 0 && loadUnreadNotifications(currentPage);
    }
    notificationType === 'unRead' && loadUnreadNotifications(currentPage);
  }, [notificationType, currentPage]);

  const setLoader = (page: number, statusType: 'initialCall' | 'apiEndCall', status?: ApiStatus, error?: ApiError) => {
    if (page === 0) {
      setStatus(statusType === 'initialCall' ? 'pending' : status ? status : 'fulfilled');
      setError(statusType === 'initialCall' ? undefined : error);
    } else {
      statusType === 'apiEndCall' && setLazyPaginationLoader(false);
    }
  };

  const loadAllNotifications = (page?: number) => {
    const pageNumber = !!page ? page : currentPage;
    setLoader(pageNumber, 'initialCall');
    getAllNotificationAsync(userId, {
      readStatus: 'all',
      page: pageNumber,
      size: pageSettings.size,
    })
      .then((res) => {
        const updatedNotifications = getAllOrUnReadNotifications(res.InAppPagedResponse.content) ?? [];
        setAllNotifications((prevState) =>
          pageNumber === 0 ? updatedNotifications : [...prevState, ...updatedNotifications]
        );
        setPageSettings(res.pagedMetaData);
        setLoader(pageNumber, 'apiEndCall', 'fulfilled');
        setallNotificationsCount(res.pagedMetaData.totalElements);
      })
      .catch((e: ApiError) => {
        setLoader(pageNumber, 'apiEndCall', 'rejected', e);
        setAllNotifications([]);
      });
  };

  const loadUnreadNotifications = (page?: number) => {
    const pageNumber = page === 0 ? page : currentPage;
    setLoader(pageNumber, 'initialCall');
    getAllNotificationAsync(userId, {
      readStatus: 'unRead',
      page: !!page ? page : currentPage,
      size: pageSettings.size,
    })
      .then((res) => {
        const updatedNotifications = getAllOrUnReadNotifications(res.InAppPagedResponse.content) ?? [];
        setUnReadNotifications((prevState) =>
          pageNumber === 0 ? updatedNotifications : [...prevState, ...updatedNotifications]
        );
        setUnReadNotificationsCount(res.pagedMetaData.totalElements);
        setLoader(pageNumber, 'apiEndCall', 'fulfilled');
      })
      .catch((e: ApiError) => {
        setLoader(pageNumber, 'apiEndCall', 'rejected', e);
        setUnReadNotifications([]);
      });
  };

  function getAllOrUnReadNotifications(notifications: IContent[]) {
    if (notifications.length === 0) return [];
    return notifications
      .filter((n) => !!n.substitutions)
      .map((n: IContent) => {
        const substitutionsKeys = Object.keys(n.substitutions);
        const {
          substitutions: {
            expiration_end_date,
            number_of_days_for_expiry,
            service_account_id,
            niagara_licensing_website,
            service_account_name,
            subscription_name,
            customer_id,
          },
          generatedDate,
          subject,
        } = n;
        const url = substitutionsKeys.includes('service_account_name')
          ? `/customers/${customer_id}/serviceaccount/${service_account_id}`
          : niagara_licensing_website;
        const notificationType = substitutionsKeys.includes('service_account_name')
          ? 'service account'
          : 'subscription';
        return {
          subject: subject,
          content: n.content.replace(n.content.split(' ')[0], '').trimLeft(),
          generatedTime: moment(new Date(generatedDate)).format('DD/MM/YYYY hh:ss A'),
          expireInDays: Number(number_of_days_for_expiry),
          readStatus: n.readStatus,
          name: (substitutionsKeys.includes('service_account_name') ? service_account_name : subscription_name) ?? '',
          renewLinkHandler: () => {
            if (!url) return;
            notificationType === 'subscription' ? window.open(url, '_blank') : navigate(url);
            onCloseNotification && onCloseNotification();
          },
          handleMarkNotificationAsRead: () => handleMarkNotificationAsRead(n.notificationId),
        };
      })
      .filter((n1) => !_.isEmpty(n1));
  }

  const handleMarkNotificationAsRead = (notificationId?: number) => {
    const payload: IUpdateReadStatus = {
      readStatus: true,
      readAllNotifications: !!notificationId ? false : true,
      notificationIds: !!notificationId ? [notificationId] : [],
    };
    if (!userId) return;
    setStatus('pending');
    updateReadStatusAsync(userId, payload)
      .then(() => {
        setStatus('fulfilled');
        setCurrentPage(0);
        setUnReadNotificationsCount((prevState) =>
          !!notificationId && Number(prevState) > 0 ? Number(prevState) - 1 : 0
        );
        notificationType === 'all' ? loadAllNotifications(0) : loadUnreadNotifications(0);
      })
      .catch(() => setStatus('rejected'));
  };

  return {
    status,
    error,
    notifications: notificationType === 'all' ? allNotifications : unReadNotifications,
    pageSettings,
    handleMarkNotificationAsRead,
    allNotificationsCount,
    unReadNotificationsCount,
    currentPage,
    setCurrentPage,
    notificationType,
    setNotificationType,
    lazyPaginationLoader,
    setLazyPaginationLoader,
  };
}
