/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { niagaraHttp } from '../../utils/niagaraHttp';
import { SearchOptions } from '../SearchOptions';
import { RoleAssignmentWithRoleNumber } from '../dto';
import { Links } from '../Links';
import { PageOptions } from '../PageOptions';
import { Paged } from '../Paged';
import { toSearchParams } from './toSearchParams';

export type UserType = 'INTERNAL' | 'PARTNER' | 'CUSTOMER' | 'UNASSIGNED';
export type RoleName =
  | 'global_admin'
  | 'partner_admin'
  | 'partner_user'
  | 'customer_admin'
  | 'customer_user'
  | 'niagara_remote'
  | 'nds_operator';
export interface IRole {
  id: number;
  roleName: string;
  description: string;
  assignableScopes: string[];
  _links: Links<'self' | 'role'>;
}
export interface NiagaraRoleAssignment {
  id: number;
  roleName: RoleName;
  scope: string;
  _links: Links<'role'>;
}
export interface NiagaraBusinessRelationship {
  id: string;
  name: string;
  accountNumber: string;
  tridiumRelationship: string;
  _links: Links<'organization'>;
}
export interface NiagaraOrganization {
  id: number;
  salesforceId: string;
  orgName: string;
  type: string;
  subtype: string;
  tenantId: string | null;
  isTechViewEnabled: boolean;
  businessRelationships: NiagaraBusinessRelationship[];
  authorizationPaths: string[];
  _links: Links<'self' | 'organization' | 'users' | 'customers' | 'partners'>;
}

export interface NiagaraTechSupportOrganization {
  _embedded: { organizations: [] };
  _links: Links<'self' | 'organization' | 'users' | 'customers' | 'partners'>;
}
export interface NiagaraMyUser {
  id: number;
  userId: string;
  contactId?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  avatarUrl?: string | null;
  role: string;
  profile: string;
  userType: UserType;
  organizationId?: number; // GlobalAdmin has no org
  organizationName?: string; // GlobalAdmin has no org
  roleAssignments: NiagaraRoleAssignment[];
  _links: Links<'self' | 'user' | 'organization'>;
}
export interface NiagaraUser {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  email: string;
  avatarUrl?: string | null;
  role?: string | null;
  profile?: string | null;
  userType: UserType;
  roleAssignments: NiagaraRoleAssignment[];
  authorizationPaths: string[];
  _links: Links<'self' | 'user' | 'organization'>;
}
export interface NiagaraUserDetail {
  id: number;
  userId: string;
  contactId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email: string;
  scope:string;
  creatorName:string; 
  avatarUrl?: string | null;
  role?: string;
  profile?: string;
  userType: UserType;
  organizationId?: number;
  organizationName?: string;
  roleAssignments: NiagaraRoleAssignment[];
  _links: Links<'self' | 'user' | 'organization'>;
  userInvitation:any;
  status?: string;
}
export interface Customer {
  id: number;
  salesforceId: string;
  orgName: string;
  type: string;
  subtype: string;
  tenantId: string | null;
  projectCount: number;
  deviceCount: number;
  authorizationPaths: string[];
  _links: Links<'self' | 'organization' | 'users' | 'customers' | 'partners'>;
}

export interface Integrator {
  id: number;
  salesforceId: string;
  orgName: string;
  type: string;
  subtype: string;
  tenantId: string | null;
  projectCount: number;
  customerCount: number;
  authorizationPaths: string[];
}
export interface Project {
  id: number;
  projectName: string;
  authorizationPaths: string[];
  _links: Links<'self' | 'project' | 'devices' | 'partner' | 'customer'>;
}
export interface Device {
  id: number;
  deviceUuid: string;
  systemGuid: string | null;
  hostId: string;
  deviceName: string | null;
  location: string | null;
  projectName: string;
  projectId: string | null;
  customerId: number;
  partnerId: number;
  tenantId: string;
  authorizationPaths: string[];
  _links: Links<'self' | 'device' | 'project'>;
}
export type DevicePatch = Partial<{
  systemGuid: string;
  hostId: string;
  deviceName: string;
  location: string;
  project: string;
  customerId: number;
  partnerId: number;
  projectName: string;
  tenantId: string;
}>;

const MANAGEMENT_API_BASE_URL = `${window.ENV.API_BASE_URL}/api/v2/management`;

export async function getMyUserAsync() {
  return niagaraHttp.get<NiagaraMyUser>(`${MANAGEMENT_API_BASE_URL}/myUser`);
}

export async function getRolesAsync() {
  const requestUrl = `${MANAGEMENT_API_BASE_URL}/roles`;
  return niagaraHttp.get<Paged<{ roles: IRole[] }>>(requestUrl).then(({ _embedded: { roles } }) => roles);
}
export interface Sort<T = any, K = keyof T> {
  field: K;
  order: 'asc' | 'desc' | undefined;
}

export function getUsersAsync(
  options?: PageOptions & SearchOptions & { userType?: 'CUSTOMER' | 'PARTNER' | undefined }
) {
  return niagaraHttp.get<Paged<{ users: NiagaraUserDetail[] }>>(
    `${MANAGEMENT_API_BASE_URL}/users${options?.keyword ? '/search/findByKeyword' : ''}${toSearchParams(options)}`
  );
}
export function getCustomerOrganizationUsersAsync(
  customerOrganizationId: number | undefined,
  options?: PageOptions & SearchOptions
) {
  return niagaraHttp.get<Paged<{ users: NiagaraUserDetail[] }>>(
    `${MANAGEMENT_API_BASE_URL}/organizations/${customerOrganizationId}/users${
      options?.keyword ? '/search/findByKeyword' : ''
    }${toSearchParams(options)}`
  );
}
export function getPartnerOrganizationAllUsersAsync(
  partnerOrganizationId: number | undefined,
  options?: PageOptions & SearchOptions
) {
  return niagaraHttp.get<Paged<{ users: NiagaraUserDetail[] }>>(
    `${MANAGEMENT_API_BASE_URL}/organizations/${partnerOrganizationId}/allUsers${
      options?.keyword ? '/search/findByKeyword' : ''
    }${toSearchParams(options)}`
  );
}
export function getPendingInvitaionAsync(
  partnerOrganizationId: number | undefined,
  options?: PageOptions & SearchOptions
) {
  return niagaraHttp.get<Paged<{ users: NiagaraUserDetail[] }>>(
    `${MANAGEMENT_API_BASE_URL}/userInvitations/search/findByPartnerId?partnerId=${partnerOrganizationId}&${toSearchParams(
      options
    )}`
  );
}
export async function getOrganizationAsync(organizationId: number) {
  return niagaraHttp.get<NiagaraOrganization>(`${MANAGEMENT_API_BASE_URL}/organizations/${organizationId}`);
}
export async function getOrganizationCustomersAsync(organizationId: number, options?: PageOptions) {
  return niagaraHttp.get<Paged<{ organizations: Customer[] }>>(
    `${MANAGEMENT_API_BASE_URL}/organizations/${organizationId}/customers${toSearchParams(options)}`
  );
}
export async function getUserCustomersAsync(
  { organizationId, userType }: { organizationId?: number; userType: UserType },
  options?: PageOptions
) {
  return organizationId === undefined
    ? { _embedded: { organizations: [] } }
    : userType === 'CUSTOMER'
    ? {
        _embedded: {
          organizations: [
            Object.assign(await getOrganizationAsync(organizationId), { projectCount: 0, deviceCount: 0 }),
          ],
        },
        page: { size: 1, number: 1, totalElements: 1, totalPages: 1 },
        _links: undefined!,
      }
    : getOrganizationCustomersAsync(organizationId, options);
}
export async function getCustomerProjectsAsync(customerId: number, options?: PageOptions, config: any = {}) {
  return niagaraHttp.get<Paged<{ projects: Project[] }>>(
    `${MANAGEMENT_API_BASE_URL}/organizations/${customerId}/projects${toSearchParams(options)}`,
    config
  );
}
export async function getCustomerProjectsByKeywordAsync(customerId: number, keyword: string, options?: PageOptions) {
  return niagaraHttp.get<Paged<{ projects: Project[] }>>(
    `${MANAGEMENT_API_BASE_URL}/organizations/${customerId}/projects/search/findByKeyword${toSearchParams({
      ...options,
      keyword,
    })}`
  );
}
export async function createProjectAsync(projectName: string, customerId: number, partnerId: number) {
  return niagaraHttp.post<Project>(`${MANAGEMENT_API_BASE_URL}/projects`, {
    projectName,
    customer: `/organizations/${customerId}`,
    partner: `/organizations/${partnerId}`,
  });
}
export async function deleteProjectAsync(projectId: number) {
  await niagaraHttp.delete<void>(`${MANAGEMENT_API_BASE_URL}/projects/${projectId}`);
}
export async function deleteDeviceAsync(deviceId: number) {
  await niagaraHttp.delete<void>(`${MANAGEMENT_API_BASE_URL}/devices/${deviceId}`);
}
export async function patchProjectAsync(projectId: number, projectName: string) {
  return await niagaraHttp.patch<Project>(`${MANAGEMENT_API_BASE_URL}/projects/${projectId}`, {
    projectName,
  });
}
export async function getCustomerDevicesAsync(customerId: number, options?: PageOptions, config: any = {}) {
  return niagaraHttp.get<Paged<{ devices: Device[] }>>(
    `${MANAGEMENT_API_BASE_URL}/organizations/${customerId}/devices${toSearchParams(options)}`,
    config
  );
}
export async function patchDeviceAsync(deviceId: number, patchDevice: DevicePatch) {
  return niagaraHttp.patch<Device>(`${MANAGEMENT_API_BASE_URL}/devices/${deviceId}`, patchDevice);
}
export async function patchTechSupportAsync(partnerId: number, patchTechSupport: boolean) {
  return niagaraHttp.patch(`${MANAGEMENT_API_BASE_URL}/organizations/${partnerId}/techSupport?enable=${patchTechSupport}`);
}
export async function getTechSupport(partnerId: number) {
  return niagaraHttp.get(`${MANAGEMENT_API_BASE_URL}/organizations/${partnerId}`);
}
export async function getTechSupportOrganizationAsync() {
  return niagaraHttp.get<NiagaraTechSupportOrganization>(`${MANAGEMENT_API_BASE_URL}/organizations/search/findByIsTechViewEnabled?enabled=true`);
}
export interface NiagaraRoleAssignmentPatch {
  create?: { role: string; scope: string }[];
  update?: { id: number; role: string; scope: string }[];
  delete?: number[];
}
export type NiagaraRoleAssignmentPatchResponse = {
  id: number;
  roleName: RoleName;
  scope: string;
  links: [{ rel: 'role'; href: string }]; // TODO - NOTE: this is different to links on other types, awaiting update from api team
}[];
export async function patchUserRoleAssignments(userId: number, patch: NiagaraRoleAssignmentPatch) {
  return niagaraHttp
    .patch<NiagaraRoleAssignmentPatchResponse>(`${MANAGEMENT_API_BASE_URL}/users/${userId}/roleAssignments`, patch)
    .then((a) =>
      // TODO - temporary hack to make the inconsistent response look like the roleAssignments
      a.map<NiagaraRoleAssignment>(({ links, ...ra }) => ({
        _links: { role: { href: links?.find((l) => l.rel === 'role')?.href ?? '/temporary/' + ra.scope } },
        ...ra,
      }))
    );
}
export interface NiagaraServiceAccount {
  id: number;
  serviceName: string;
  description: string | null;
  clientId: string;
  expiration: string;
  roleAssignments: NiagaraRoleAssignment[];
  _links: Links<'self' | 'serviceAccount' | 'partner' | 'customer'>;
}
export interface NiagaraServiceAccountSecret {
  secret: string;
}
export type NiagaraServiceAccountDetailPatch = Partial<{
  serviceName: string;
  description: string | null;
  roleAssignments: { role: string; scope: string }[];
}>;

export function getServiceAccounts(organizationId: number, keyword?: string) {
  return niagaraHttp.get<
    Paged<{
      serviceAccounts: NiagaraServiceAccount[];
    }>
  >(
    `${MANAGEMENT_API_BASE_URL}/organizations/${organizationId}/serviceAccounts${
      keyword ? `/search/findByKeyword?keyword=${keyword}&` : '?'
    }size=1000`
  );
}
export const generateSecretAsync = async ({ serviceId, expiration }: { serviceId: number; expiration: string }) => {
  return niagaraHttp.post<NiagaraServiceAccountSecret>(
    `${MANAGEMENT_API_BASE_URL}/serviceAccounts/${serviceId}/secret`,
    { expiration }
  );
};
export async function getServiceAccountDetailAsync(serviceAccountId: number) {
  return niagaraHttp.get<NiagaraServiceAccount>(`${MANAGEMENT_API_BASE_URL}/serviceAccounts/${serviceAccountId}`);
}
export async function createServiceAccountAsync({
  serviceName,
  description,
  customerId,
  partnerId,
  localRoleAssignments,
}: {
  serviceName: string;
  description?: string | null;
  customerId: number;
  partnerId: number;
  localRoleAssignments: RoleAssignmentWithRoleNumber[];
}) {
  return niagaraHttp.post<NiagaraServiceAccount>(`${MANAGEMENT_API_BASE_URL}/serviceAccounts`, {
    serviceName,
    description,
    roleAssignments: localRoleAssignments.map(({ roleNumber, scope }) => ({ role: `/roles/${roleNumber}`, scope })),
    customer: `/organizations/${customerId}`,
    partner: `/organizations/${partnerId}`,
  });
}
export async function updateServiceAccountDetailAsync(
  serviceAccountId: number,
  details: NiagaraServiceAccountDetailPatch
) {
  return niagaraHttp.patch<NiagaraServiceAccount>(
    `${MANAGEMENT_API_BASE_URL}/serviceAccounts/${serviceAccountId}`,
    details
  );
}
export async function patchServiceAccountRoleAssignmentsAsync(
  serviceAccountId: number,
  patch: NiagaraRoleAssignmentPatch
) {
  return niagaraHttp
    .patch<NiagaraRoleAssignmentPatchResponse>(
      `${MANAGEMENT_API_BASE_URL}/serviceAccounts/${serviceAccountId}/roleAssignments`,
      patch
    )
    .then((a) =>
      // TODO - temporary hack to make the inconsistent response look like the roleAssignments
      a.map<NiagaraRoleAssignment>(({ links, ...ra }) => ({
        _links: { role: { href: links?.find((l) => l.rel === 'role')?.href ?? '/temporary/' + ra.scope } },
        ...ra,
      }))
    );
}
export function deleteServiceAccount(serviceAccountId: number) {
  return niagaraHttp.delete(`${MANAGEMENT_API_BASE_URL}/serviceAccounts/${serviceAccountId}`);
}
export function deletePendingInvitation(id: string) {
  return niagaraHttp.delete(`${MANAGEMENT_API_BASE_URL}/userInvitations/${id}`);
}
