/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React from 'react';
import { NiagaraButton2 } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import ErrorIcon from '../../../../assets/image/error-icon.png';
import './UnsubscribeErrorPage.scss';
import { DEFAULT_ERROR_TITLE } from '../../../../utils/Constants';

export function UnsubscribeErrorPage({ onRetry }: { onRetry: () => void }) {
  return (
    <div className='unsubscribe-error-body'>
      <div className='error-icon'>
        <img width='100%' height='72' src={ErrorIcon} alt='error' />
      </div>
      <div className='error-title'>{DEFAULT_ERROR_TITLE}</div>
      <div className='error-text'>
        Sorry, we were unable to connect to the service,
        <br />
        Please try again.
        <div className='support-message'>
          <br />
          If the problem persists, contact your direct
          <br />
          support or Tridium for assistance:
          <br />
          Toll-free: +1 877-305-1745
          <br />
          Email: <a href='mailto:support@tridium.com'>support@tridium.com</a>
        </div>
      </div>
      <NiagaraButton2 type='primary' content='Retry' onClick={onRetry} />
    </div>
  );
}
