/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React from 'react';
import { SortableHeader } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib/lib/table';
export type IOrder = 'asc' | 'desc';
export interface HeaderCellProps<T> {
  name: string;
  sortOnField: keyof T;
  sortBy: keyof T;
  setSortBy: (sortBy: keyof T) => void;
  order: IOrder;
  setOrder: (sortBy: IOrder) => void;
}

export default function HeaderCell<T>({ name, sortOnField, sortBy, setSortBy, order, setOrder }: HeaderCellProps<T>) {
  return (
    <SortableHeader
      headerCellName={name}
      isActive={sortOnField === sortBy}
      order={order}
      onSort={() => {
        if (sortOnField !== sortBy) {
          setSortBy(sortOnField);
          setOrder('asc');
        } else {
          setOrder(order === 'asc' ? 'desc' : 'asc');
        }
      }}
    />
  );
}
