/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useRef } from 'react';
import { Divider } from '@scuf/common';
import './NotificationComponent.scss';
import { NiagaraButton2 } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { NotificationItem } from './NotificationItem';
import { NoNotificationComponent } from './NoNotificationComponent';
import Status from '../../../utils/Status';
import { useNotificationsController } from './useNotificationsController';
import { Loader } from '../../../utils/Loader';
interface NotificationComponentProps {
  onCloseNotification: (dataClose?: string) => void;
}

export function NotificationComponent({ onCloseNotification }: NotificationComponentProps) {
  const listInnerRef = useRef(null);
  const {
    status,
    error,
    notifications,
    pageSettings,
    handleMarkNotificationAsRead,
    allNotificationsCount,
    currentPage,
    setCurrentPage,
    notificationType,
    setNotificationType,
    unReadNotificationsCount,
    lazyPaginationLoader,
    setLazyPaginationLoader,
  } = useNotificationsController(onCloseNotification);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && pageSettings.totalPages >= currentPage + 2) {
        setLazyPaginationLoader(true);
        setTimeout(() => setCurrentPage((prevPage) => prevPage + 1), 3000);
      }
    }
  };

  const changeNotificationType = (type: 'all' | 'unRead') => {
    setLazyPaginationLoader(false);
    setNotificationType(type);
    setCurrentPage(0);
  };
  return (
    <div className='notification-model-container'>
      <div className='notification-header'>
        <span className='setting-icon'>
          <NiagaraButton2
            type='secondary-link'
            icon='Settings'
            iconSize={16}
            iconColor='#0071B3'
            onClick={() => onCloseNotification('ConfigureNotification')}
          />
        </span>
        <span className='title'>Notifications</span>
        <span className='close-icon'>
          <NiagaraButton2
            type='secondary-link'
            icon='Close'
            iconColor='#606060'
            onClick={() => onCloseNotification()}
          />
        </span>
      </div>
      <Divider fitted={true} />
      <div className='notification-header'>
        <div className='notification-read'>
          <button
            className={`label-btn${notificationType === 'all' ? ' active' : ''}`}
            onClick={() => changeNotificationType('all')}
          >
            All ({allNotificationsCount})
          </button>
          <button
            className={`label-btn${notificationType === 'unRead' ? ' active' : ''}`}
            onClick={() => changeNotificationType('unRead')}
          >
            Unread ({unReadNotificationsCount})
          </button>
        </div>
        <div className='MarkRead'>
          <NiagaraButton2
            className='markAllRead-button'
            type='secondary-link'
            icon='MarkAllRead'
            content='Mark all as read'
            onClick={() => handleMarkNotificationAsRead()}
            iconSize={16}
            disabled={unReadNotificationsCount === 0}
          />
        </div>
      </div>
      <Divider fitted={true} />
      <Status status={status} error={error}>
        <div
          id='notification-card'
          className='card-content'
          key='notification-list'
          onScroll={onScroll}
          ref={listInnerRef}
        >
          {notifications.length > 0
            ? notifications.map((n, i) => (
                <>
                  <NotificationItem key={`notification-item${i}`} {...n} />
                  {i !== notifications.length - 1 && <Divider fitted={true} key={`notification-divider${i}`} />}
                </>
              ))
            : status === 'fulfilled' &&
              !lazyPaginationLoader && (
                <NoNotificationComponent title={`No${notificationType === 'unRead' ? ' new' : ''} notifications`} />
              )}
          {lazyPaginationLoader && (
            <div className='load-more'>
              <Loader className='pending-loader' text=' ' />
            </div>
          )}
        </div>
      </Status>
    </div>
  );
}
