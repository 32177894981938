/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import {
  NiagaraButton2,
  NiagaraModal,
  NiagaraToggle,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Divider, Loader, Modal } from '@scuf/common';
import './ConfigureNotificationsModal.scss';
import { notificationSettingsMockData } from './mockData';
import { useUserRoleStore } from '../../../userroles/UseUserRoleStore';
import {
  getNotificationSettingsAsync,
  NotificationSettings,
  saveNotificationSettingsAsync,
} from '../../../api/notifications';
import { ApiError } from '../../../utils/niagaraHttp';
import { showErrorToast, showSuccessToast } from '../../../toast/Toast';

interface IConfigureNotificationsModal {
  onClose: () => void;
}

export function ConfigureNotificationsModal({ onClose }: IConfigureNotificationsModal) {
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettings[]>([]);
  const [isLoading, setLoading] = useState(false);
  const {
    currentUser: { id: userId },
  } = useUserRoleStore();
  const [notificationServiceAccountEmail, setNotificationServiceAccountEmail] = useState<boolean>(false);
  const [notificationServiceAccountInApp, setNotificationServiceAccountInApp] = useState<boolean>(false);
  const [notificationSubscriptionEmail, setNotificationSubscriptionEmail] = useState<boolean>(false);
  const [notificationSubscriptionInApp, setNotificationSubscriptionInApp] = useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      getNotificationSettings();
    }
  }, [userId]);

  useEffect(() => {
    if (notificationSettings.length) {
      const serviceAccountSettingEmail = notificationSettings.find(
        (settings) => settings.eventTypeId === 2 && settings.notificationType === 'email'
      );
      const serviceAccountSettingInApp = notificationSettings.find(
        (settings) => settings.eventTypeId === 2 && settings.notificationType === 'inApp'
      );
      const subscriptionSettingEmail = notificationSettings.find(
        (settings) => settings.eventTypeId === 1 && settings.notificationType === 'email'
      );
      const subscriptionSettingInApp = notificationSettings.find(
        (settings) => settings.eventTypeId === 1 && settings.notificationType === 'inApp'
      );
      setNotificationServiceAccountEmail(serviceAccountSettingEmail?.subscribe ?? false);
      setNotificationServiceAccountInApp(serviceAccountSettingInApp?.subscribe ?? false);
      setNotificationSubscriptionEmail(subscriptionSettingEmail?.subscribe ?? false);
      setNotificationSubscriptionInApp(subscriptionSettingInApp?.subscribe ?? false);
    }
  }, [notificationSettings]);

  const getNotificationSettings = () => {
    setLoading(true);
    getNotificationSettingsAsync(userId)
      .then((response) => {
        setNotificationSettings(response);
      })
      .catch((_: ApiError) => {
        showErrorToast('An error occurred while fetching notification details.');
        onClose();
      })
      .finally(() => setLoading(false));
  };

  const saveNotificationSettings = () => {
    if (!userId) return;
    setLoading(true);
    const payload = [];
    if (notificationServiceAccountEmail !== initialNotificationServiceAccountEmail) {
      payload.push({
        eventTypeId: 2,
        subscribe: notificationServiceAccountEmail as boolean,
        notificationType: 'email',
      });
    }
    if (notificationServiceAccountInApp !== initialNotificationServiceAccountInApp) {
      payload.push({
        eventTypeId: 2,
        subscribe: notificationServiceAccountInApp as boolean,
        notificationType: 'inApp',
      });
    }
    if (notificationSubscriptionEmail !== initialNotificationSubscriptionEmail) {
      payload.push({ eventTypeId: 1, subscribe: notificationSubscriptionEmail as boolean, notificationType: 'email' });
    }
    if (notificationSubscriptionInApp !== initialNotificationSubscriptionInApp) {
      payload.push({ eventTypeId: 1, subscribe: notificationSubscriptionInApp as boolean, notificationType: 'inApp' });
    }

    saveNotificationSettingsAsync(userId, payload)
      .then((response) => {
        showSuccessToast('Notification setting updated.');
      })
      .catch((_: ApiError) => {
        showErrorToast('An error occurred while Updating notification details.');
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const initialNotificationServiceAccountEmail = notificationSettings?.find(
    (settings) => settings.eventTypeId === 2 && settings.notificationType==='email'
  )?.subscribe;
   const initialNotificationServiceAccountInApp = notificationSettings?.find(
     (settings) => settings.eventTypeId === 2 && settings.notificationType === 'inApp'
   )?.subscribe;
  const initialNotificationSubscriptionEmail = notificationSettings?.find(
    (settings) => settings.eventTypeId === 1 && settings.notificationType==='email'
  )?.subscribe;
  const initialNotificationSubscriptionInApp = notificationSettings?.find(
    (settings) => settings.eventTypeId === 1 && settings.notificationType === 'inApp'
  )?.subscribe;

  return (
    <NiagaraModal className='configuration-notifications-modal' open={true} size='large'>
      <Modal.Header>
        <div className='modal-heading'>Configure Notifications</div>
        <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={isLoading} overlayOpacity={0.5}>
          <div className='notification-type-wrapper'>
            <div className='notification-type'>
              <div className='notification-type-header-wrapper'>
                <div className='notification-type-header'>Notification Type</div>
                <div className='notification-type-header-name'>Email</div>
                <div className='notification-type-header-name'>In App</div>
              </div>
              <Divider fitted={true} />
              <div className='notification-item'>
                <div className='notification-item-name'>Service account updates</div>
                <div className='toggleNotification'>
                  <NiagaraToggle
                    disabled={false}
                    checked={notificationServiceAccountEmail}
                    onChange={() => setNotificationServiceAccountEmail((prev) => !prev)}
                  />
                </div>
                <div className='toggleNotification'>
                  <NiagaraToggle
                    disabled={false}
                    checked={notificationServiceAccountInApp}
                    onChange={() => setNotificationServiceAccountInApp((prev) => !prev)}
                  />
                </div>
              </div>
              <div className='notification-item'>
                <div className='notification-item-name'>Subscription updates</div>
                <div className='toggleNotification'>
                  <NiagaraToggle
                    disabled={false}
                    checked={notificationSubscriptionEmail}
                    onChange={() => setNotificationSubscriptionEmail((prev) => !prev)}
                  />
                </div>
                <div className='toggleNotification'>
                  <NiagaraToggle
                    disabled={false}
                    checked={notificationSubscriptionInApp}
                    onChange={() => setNotificationSubscriptionInApp((prev) => !prev)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Loader>
      </Modal.Content>
      <Modal.Footer>
        <div className='button-container'>
          <NiagaraButton2 content='Cancel' type='secondary' onClick={() => onClose()} />
          <NiagaraButton2
            content='Save'
            disabled={
              notificationServiceAccountEmail === initialNotificationServiceAccountEmail &&
              notificationSubscriptionEmail === initialNotificationSubscriptionEmail &&
              notificationServiceAccountInApp === initialNotificationServiceAccountInApp &&
              notificationSubscriptionInApp === initialNotificationSubscriptionInApp
            }
            onClick={saveNotificationSettings}
            type='primary'
          />
        </div>
      </Modal.Footer>
    </NiagaraModal>
  );
}
