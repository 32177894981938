/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { getAuthorization } from '../utils/roleAssignment/roleAssignment';
import { RoleAssignmentWithRoleNumber, convertToRoleAssignment } from '../api/dto';
import {
  PARTNER_ADMIN,
  PARTNER_USER,
  CUSTOMER_ADMIN,
  CUSTOMER_USER,
  NIAGARA_REMOTE,
  NDS_OPERATOR,
  GLOBAL_ADMIN,
} from '../utils/roleAssignment/typesAndConstants';
import { Customer, Device, NiagaraUser, NiagaraMyUser, NiagaraOrganization, Project } from '../api/management';
import { useMemo } from 'react';
import { useCurrentUser } from '../utils/Authentication/ProtectedContent';

// TODO - make this the default export
export const usePermissions = () => _usePermissions(useCurrentUser());

export default _usePermissions;
export function _usePermissions({ roleAssignments }: NiagaraMyUser) {
  return useMemo(
    () => actionPermissions(permissionCheckerByRoleAssignment(roleAssignments.map(convertToRoleAssignment) ?? [])),
    [roleAssignments]
  );
}

function actionPermissions(isPermitted: ReturnType<typeof permissionCheckerByRoleAssignment>) {
  return {
    Management: {
      ViewCustomers: isPermitted<NiagaraOrganization>`PAPU____NRNO`,
      Projects: {
        ViewProjects: isPermitted<NiagaraOrganization | Customer>`PAPUCACUNRNO`,
        CreateProject: isPermitted<NiagaraOrganization | Customer>`PA`,
        EditProject: isPermitted<Project>`PA`,
        DeleteProject: isPermitted<Project>`PA`,
      },
      Devices: {
        ViewDevices: isPermitted<NiagaraOrganization | Customer>`PAPUCACUNRNO`,
        RegisterDevice: isPermitted<Project | Device>`PA`,
        EditDevice: isPermitted<Device>`PA`,
        MoveToProject: isPermitted<Project>`PA`, // additional sub-permission of edit device
        DeleteDevice: isPermitted<Device>`PA`,
      },
      BulkExport: {
        ViewExports: isPermitted<NiagaraOrganization | Customer>`PAPUCACU`,
        DownloadExport: isPermitted<NiagaraOrganization | Customer>`PAPUCA`,
        InitiateExport: isPermitted<NiagaraOrganization | Customer>`PAPUCA`,
        DeleteExport: isPermitted<NiagaraOrganization | Customer>`PAPUCA`,
      },
      Users: {
        /** An admin role on any entity within the organization allows the user
         * to view other users within the organization, as they will need to to
         * manage roles. */
        ViewUsers: isPermitted<NiagaraOrganization>`PA__CA`,
        /** Users can grant roles to any entity that they have an admin role on,
         * either explicitly or via a role inherited from an ancestor.
         * Customer Admin can only modify roles of other users in their org. */
        ModifyUserRoles: isPermitted<NiagaraUser>`PA__CA____`,
      },
      /** Must have Partner Admin at the partner or customer level to manage
       * service accounts. Partner admin at project or device level is not
       * sufficient. */
      ServiceAccount: {
        ViewServiceAccount: isPermitted<NiagaraOrganization | Customer>`PA`,
        CreateServiceAccount: isPermitted<NiagaraOrganization | Customer>`PA`,
        EditServiceAccount: isPermitted<NiagaraOrganization | Customer>`PA`,
        DeleteServiceAccount: isPermitted<NiagaraOrganization | Customer>`PA`,
        RegenerateServiceAccountSecret: isPermitted<NiagaraOrganization | Customer>`PA`,
        ModifyServiceAccountRoles: isPermitted<NiagaraOrganization | Customer>`PA`,
      },
    },
    NiagaraDataServices: {
      QueryModel: isPermitted<Device>`PAPUCACU__NO`,
      QueryData: isPermitted<Device>`PAPUCACU__NO`,
      CreateReport: isPermitted<Device>`PA__CA____`,
      DeleteReport: isPermitted<Device>`PA__CA____`,
      ViewReport: isPermitted<Device>`PAPUCACU__NO`,
      Export: isPermitted<Device>`PAPUCACU__NO`,
    },
    NiagaraRecover: {
      View: isPermitted<Device>`PAPUCACU__`,
      Edit: isPermitted<Device>`PA`,
      Download: isPermitted<Device>`PAPUCA____`,
      Delete: isPermitted<Device>`PA`,
    },
    NiagaraRemote: {
      Connect: isPermitted<Device>`PA__CA__NR`,
    },
    NiagaraAlarms: {
      Query: isPermitted<Device>`PAPUCACU__NO`,
      Manage: isPermitted<Device>`PA__CA____NO`,
    },
    LiveReadWrite: {
      ReadPoint: isPermitted<Device>`PAPUCACU__NO`,
      WritePoint: isPermitted<Device>`PA__CA____NO`,
    },
  };
}

const ROLES = {
  GA: GLOBAL_ADMIN,
  PA: PARTNER_ADMIN,
  PU: PARTNER_USER,
  CA: CUSTOMER_ADMIN,
  CU: CUSTOMER_USER,
  NR: NIAGARA_REMOTE,
  NO: NDS_OPERATOR
};

const permissionCheckerByRoleAssignment =
  (ra: RoleAssignmentWithRoleNumber[]) =>
  <T extends { authorizationPaths: string[] }>(abr: readonly string[]) =>
  ({ authorizationPaths }: T) =>
    Object.entries(ROLES).some(
      ([abr_, role_]) =>
        abr.some(a => a.includes(abr_)) &&
        /**
         * TODO - BUG? SHOULD WE BE USING OVERALL OR EFFECTIVE?
         * overall seems to be resulting in "CUSTOM" for
         * customer users having partial permissions when
         * they should be "ADMIN".
         */
        getAuthorization(ra, authorizationPaths).overall.some(role => role.roleNumber === role_.roleNumber)
    );
